import { createRouter, createWebHashHistory } from "vue-router";
import { projectDefault } from "./projoctDefault"; //项目默认 mi si mi 英文版 动态路由
import { getTheUserThemeDataInfo } from "@/api";
import { ElMessage } from "element-plus";

//定义后台管理用户字段
export const adminUserId = "e0ba71b0-f863-11ee-aa34-6740f80d5348";

const routes = [];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

//获取后台用户选用的主题
export const getUserThemeData = async () => {
  //根据字段去查找使用的是什么主题
  // await getTheUserThemeDataInfo({
  //   uuid:adminUserId
  // })
  //     .then(res=>{
  //       if(res.data.code === -1){
  //         ElMessage.error("获取失败，请稍后再试")
  //       }
  //       else if(res.data.code === 0){
  //         if(res.data.data === '默认主题'){
  projectDefault.forEach((item) => {
    router.addRoute(item);
  });
  //     }
  //     else{//如果查出来的是一个其他的还没有被开发的主题
  //       projectDefault.forEach(item=>{
  //         router.addRoute(item);
  //       })
  //     }
  //   }
  // })
  // .catch(err=>{})
};

//路由守卫
router.beforeEach(async (to, from, next) => {
  //在路由跳转之前，发送请求获取，后台用户选定的主题
  await getUserThemeData();
  next();
});

export default router;
