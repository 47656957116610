




<template>
  <router-view></router-view>
</template>



<style lang="less">
html , body , #app{
  width: 100%;
  min-width: 1450px;
  //height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto; /* 允许滚动 */
  scrollbar-width: none; /* 对于 Firefox 隐藏滚动条 */
  background: #FFFFFF;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
</style>
